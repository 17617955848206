import React, {useState} from 'react'
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const ChangeCodeAd = (props) =>{
    const adminData = props.adminData
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const formSchema = Yup.object().shape({
        password: Yup.string().required("Required"),
        code: Yup.string().required("Required"),
        newcode: Yup.string().required("Required"),
        newcodeck: Yup.string().required("Required").oneOf([Yup.ref('newcode')], "Passwords must match")
    })

    const changeCode = (values) =>{
        setIsLoading(true)
        axios
            .put(`${process.env.REACT_APP_API_ADD}/admin/${adminData.id}`, values, {withCredentials: true})
            .then(res =>{
                console.log(res.data)
                setIsLoading(false)
                setMessage(`${adminData.adminname}'s code has changed. `)
            })
            .catch(err =>{
                console.log(err)
                setMessage('Something went wrong')
                setIsLoading(false)
            })
    }

    //console.log(adminData)
    return(
        <div className='change-pass-ad'>
            <div className='form-container'>
                <h2>Change Code</h2>
                <p className='err-msg'>{message}</p>
                <div className='form-box'>
                    <Formik
                        initialValues={{
                            password: '',
                            code: '',
                            newcode: '',
                            newcodeck: ''
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, {resetForm}) =>{
                            let submitValues ={
                                password: values.password,
                                code: values.code,
                                newcode: values.newcode
                            }
                            console.log(submitValues)
                            changeCode(submitValues)
                            resetForm()
                            
                        }}
                    >
                    
                        {({errors, touched}) =>(
                            <Form>
                                {isLoading ?
                                    <div className='loading'> 
                                        <FontAwesomeIcon icon={faSpinner} spin/> 
                                    </div>
                                :
                                
                                    <div>
                                        <div>
                                            <div>
                                            <label htmlFor='password'>Current Password:</label> 
                                            {errors.password && touched.password ? (
                                                <span className='red'> {errors.password}</span>
                                            ) : null}
                                            </div>
                                            <Field name="password" type="password" className="input current" />
                                        </div>

                                        <div className='btm-spacer'>
                                            <div>
                                            <label htmlFor='code'>Current Code:</label> 
                                            {errors.code && touched.code ? (
                                                <span className='red'> {errors.code}</span>
                                            ) : null}
                                            </div>
                                            <Field name="code" type="password" className="input current" />
                                        </div>

                                        <div>
                                            <div>
                                            <label htmlFor='newcode'>New Code:</label> 
                                            {errors.newcode && touched.newcode ? (
                                                <span className='red'> {errors.newcode}</span>
                                            ) : null}
                                            </div>
                                            <Field name="newcode" type="password" className="input new" />
                                        </div>

                                        <div className='btm-spacer'>
                                            <div>
                                            <label htmlFor='newcodeck'>Re-Enter New Password:</label> 
                                            {errors.newcodeck && touched.newcodeck ? (
                                                <span className='red'> {errors.newcodeck}</span>
                                            ) : null}
                                            </div>
                                            <Field name="newcodeck" type="password" className="input new" />
                                        </div>

                                        <div>
                                            <button type="submit">Change Code</button>
                                        </div>
                                    </div>
                                }

                            </Form> 
                        )}

                    </Formik>
                </div>
                
            </div>
        </div>
    )
}

export default ChangeCodeAd