import React, { useState } from 'react'
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


const AdminLogin = (props) => {

    const setMessage = props.setMessage
    const setAdminData = props.setAdminData
    const message = props.message

    const [isLoading, setIsLoading] = useState(false)

    const formSchema = Yup.object().shape({
        adminname: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        code: Yup.string().required("Required")

    })

    const login = (values) =>{
        setIsLoading(true)
        axios
            .post(`${process.env.REACT_APP_API_ADD}/admin/login`, values, {withCredentials: true})
            .then(res =>{
                setIsLoading(false)
                setAdminData(res.data)
                setMessage("Logged In")
                
            })
            .catch(err =>{
                setMessage("Login Failed")
                console.log(err)
                setIsLoading(false)
            })
    }

    return(
        <article className='admin-login'>
            <div className='adlog-container'>
                <p className='err-msg'>{message}</p>
                <Formik
                    initialValues={{
                        adminname: '',
                        password: '',
                        code: ''
                    }}
                    validationSchema={formSchema}
                    onSubmit={values =>{
                        values.code = values.code.toString()
                        //console.log(values)
                        login(values)
                    }}
                >
                    {({errors, touched}) =>(
                        <Form className='adlog-form'>
                            {isLoading ? 
                                <div className='loading2'> 
                                    <FontAwesomeIcon icon={faSpinner} spin/> 
                                </div>
                            :
                                <div>
                                    <div>
                                        <div className='label'>
                                            <label htmlFor='adminname'>Name:</label>
                                            {errors.name && touched.name ? (
                                                <span className='red'> {errors.name}</span>
                                            ): null}
                                        </div>
                                        <Field name="adminname" type="input" className="input"/>
                                    </div>

                                    <div>
                                        <div className='label'>
                                            <label htmlFor='password'>Password:</label>
                                            {errors.password && touched.password ? (
                                                <span className='red'> {errors.password}</span>
                                            ): null}
                                        </div>
                                        <Field name="password" type="password" className="input"/>
                                    </div>

                                    <div>
                                        <div className='label'>
                                            <label htmlFor='code'>Code:</label>
                                            {errors.code && touched.code ? (
                                                <span className='red'> {errors.code}</span>
                                            ): null}
                                        </div>
                                        <Field name="code" type="password" className="input"/>
                                    </div>

                                    <button type="submit">Login</button>
                                </div>
                            }
                        </Form>
                    )}
                    
                </Formik>

            </div>
        </article>
    )
}

export default AdminLogin