import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import EpisodeCard from './EpisodeCard';

const EpisodeWindow = () => {

    const [episodes, setEpisodes] = useState([])
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/episodes/all`, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setEpisodes(res.data)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    },[message])

    console.log(episodes)

    return(
        <article className='episode-window'>
            {isLoading ? 
                <div className='loading'> 
                    <FontAwesomeIcon icon={faSpinner} spin/> 
                </div>
            :
                <div>
                    {episodes.length > 0 ? 
                        
                        episodes.map((data, index) =>(
                            <div key={index}>
                                <EpisodeCard data={data} setMessage={setMessage} />
                            </div>
                        ))
                    :
                        <p>No Data</p>
                    }
                </div>
            }
            
        </article>
    )
}

export default EpisodeWindow;