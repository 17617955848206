import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner, faPlay, faPause, faVolumeMute, faVolumeHigh} from "@fortawesome/free-solid-svg-icons";

import useVideoPlayer from '../../hooks/useVideoPlayer';

const RssPlayer = () => {

    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const [art, setArt] = useState("")
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")
    const [media, setMedia] = useState("")
    const [episodeIndex, setEpisodeIndex] = useState(0)

    const audio = media
    const audioElement = useRef(null)
    const {
        isPlaying,
        progress,
        speed,
        isMuted,
        togglePlay,
        handelOnTimeUpdate,
        handelVideoProgress,
        handelVideoSpeed,
        toggleMute,
    } = useVideoPlayer(audioElement)

    useEffect(()=>{
        setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/rss/`, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setItems(res.data.items)
                setArt(res.data.items[episodeIndex].itunes.image)
                setDescription(res.data.items[episodeIndex].contentSnippet)
                setTitle(res.data.items[episodeIndex].title)
                setMedia(res.data.items[episodeIndex].enclosure.url)

            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    },[episodeIndex])

    const secToTime = (seconds) => {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    }

    const selectNewEp = (index) => {
        setIsLoading(true)
        if(isPlaying){
           togglePlay() 
        }
        setEpisodeIndex(index)
        setIsLoading(false)
    }
    

    //console.log(items)
    return (
        <article className='rss-player'>

            {isLoading ?
                <div className='load'>
                    <FontAwesomeIcon icon={faSpinner} spin />
                </div> 
                
            :
                <div>
                    <div className='top-row'>
                        <div>
                            <img src={art} alt="Askin Haskins Podcast Logo" />
                        </div>
                    
                        <div className='description'>
                            <h3>{title}</h3>
                            <p>{description}</p>
                        
                        </div>
                        
                    </div>
                    <div className='player'>
                        
                        <div className='audio' style={{'border': 'none'}}>
                            <audio src={audio} ref={audioElement} onTimeUpdate={handelOnTimeUpdate} />
                            <div className='controlls' >
                                
                                <button onClick={togglePlay} className="play-btn">
                                    {isPlaying ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
                                </button>
                                
                                <input type="range" min="0" max="100" value={progress || 0} onChange={(e)=> handelVideoProgress(e)} className="slider"/>

                                <select value={speed} onChange={(e) => handelVideoSpeed(e)} className="speed">
                                    <option value="0.50">0.50x</option>
                                    <option value="1">1x</option>
                                    <option value="1.25">1.25x</option>
                                    <option value="2">2x</option>
                                </select>

                                <button onClick={toggleMute} className="mute">
                                    {isMuted ? <FontAwesomeIcon icon={faVolumeMute}/> : <FontAwesomeIcon icon={faVolumeHigh}/>}
                                </button>

                            </div>
                            
                        </div>


                        
                    </div>
                    
                </div>
            }

            <div className='episodes-list'>
                {items.map((item, index) => (
                    <div className='entry' key={index} onClick={() => selectNewEp(index) }>
                        <img src={item.itunes.image} alt={`Episode ${item.itunes.episode || "0"} Logo`}/>
                        <h4>{item.title}</h4>
                        <p>{new Date(item.pubDate).toLocaleDateString()}</p>
                        <p className='last'>{secToTime(item.itunes.duration)}</p>
                    </div>
                ))}
            </div>
           
            
        </article>
    )
}

export default RssPlayer;