import React from 'react'
import Logo from '../../../../common/logo/Logo';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faPatreon } from '@fortawesome/free-brands-svg-icons';

const QuickDescrip = (props) => {

    const setModalState = props.setModalState

    return(
        <article className='quick-descrip'>
            <div className='logo-box'>
                <Logo size={50}/>
                <div className='icon facebook'>
                    <a href='http://www.facebook.com/askinhaskins'>
                        <FontAwesomeIcon icon={faFacebookSquare} /> 
                    </a>
                   
                </div> 
                <div className='icon twitter'>
                
                    <a href='http://www.twitter.com/askinhaskins'>
                        <FontAwesomeIcon icon={faTwitterSquare} /> 
                    </a>
                   
                </div>

                <div className='icon-2 patreon'>
                
                    <a href='https://www.patreon.com/askinhaskins'>
                        <FontAwesomeIcon icon={faPatreon} /> 
                    </a>
                   
                </div>
                
            </div>
            
            <div className='descrip-container'>
               <p><span className='purp'>Askin' Haskin's</span> is a podcast that aims to answer the hard hitting questions.</p>
               <p>Our aim is not all that great</p> 
               <p>But that won't stop us from kicking back a few brews and answering any question that comes our way</p>
               <p>Listen in as hosts Rick, Claudette, Matt, Dan, and producer David attempt to navigate this maze we call life.</p> 
               <p>We're all about to get lost.</p>
            
                <div className='btn-container'>
                    <button className='q-btn' onClick={() =>setModalState('block')}>
                        Ask A Question
                    </button>
                    <Link to={'/join'} >
                        <button className='s-btn'>
                            Subscribe For Bonus Content
                        </button>
                    </Link>
                    
                </div>
            
            </div>

           
            
        </article>
    )
}

export default QuickDescrip;