import React, {useState} from 'react'
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";


const ChangePassAd = (props) =>{
    const adminData = props.adminData
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const formSchema = Yup.object().shape({
        password: Yup.string().required("Required"),
        code: Yup.string().required("Required"),
        newpass: Yup.string().required("Required"),
        newpassck: Yup.string().required("Required").oneOf([Yup.ref('newpass')], "Passwords must match")
    })

    const changePass = (values) =>{
        setIsLoading(true)
        axios
            .put(`${process.env.REACT_APP_API_ADD}/admin/${adminData.id}`, values, {withCredentials: true})
            .then(res =>{
                //console.log(res.data)
                setIsLoading(false)
                //setSent(true)
                setMessage(`${adminData.adminname}'s password has changed. `)
            })
            .catch(err =>{
                console.log(err)
                setMessage('Something went wrong')
                setIsLoading(false)
            })
    }

    //console.log(adminData)
    return(
        <div className='change-pass-ad'>
           
            <div className='form-container'>
                <h2>Change Password</h2>
                <p className='err-msg'>{message}</p>
                <div className='form-box'>
                    <Formik
                        initialValues={{
                            password: '',
                            code: '',
                            newpass: '',
                            newpassck: ''
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, {resetForm}) =>{
                            let submitValues ={
                                password: values.password,
                                code: values.code,
                                newpass: values.newpass
                            }
                            console.log(submitValues)
                            changePass(submitValues)
                            resetForm()
                            
                        }}
                    >
                    
                        {({errors, touched}) =>(
                            <Form>
                                {isLoading ?
                                        <div className='loading'> 
                                            <FontAwesomeIcon icon={faSpinner} spin/> 
                                        </div>
                                :
                                    <div>
                                        <div>
                                            <div>
                                            <label htmlFor='password'>Current Password:</label> 
                                            {errors.password && touched.password ? (
                                                <span className='red'> {errors.password}</span>
                                            ) : null}
                                            </div>
                                            <Field name="password" type="password" className="input current" />
                                        </div>

                                        <div className='btm-spacer'>
                                            <div>
                                            <label htmlFor='code'>Current Code:</label> 
                                            {errors.code && touched.code ? (
                                                <span className='red'> {errors.code}</span>
                                            ) : null}
                                            </div>
                                            <Field name="code" type="password" className="input current" />
                                        </div>

                                        <div>
                                            <div>
                                            <label htmlFor='newpass'>New Password:</label> 
                                            {errors.newpass && touched.newpass ? (
                                                <span className='red'> {errors.newpass}</span>
                                            ) : null}
                                            </div>
                                            <Field name="newpass" type="password" className="input new" />
                                        </div>

                                        <div className='btm-spacer'>
                                            <div>
                                            <label htmlFor='newpassck'>Re-Enter New Password:</label> 
                                            {errors.newpassck && touched.newpassck ? (
                                                <span className='red'> {errors.newpassck}</span>
                                            ) : null}
                                            </div>
                                            <Field name="newpassck" type="password" className="input new" />
                                        </div>

                                        <div>
                                            <button type="submit">Change Password</button>
                                        </div>
                                    </div>
                                }

                            </Form> 
                        )}

                    </Formik>
                </div>
                
            </div>
           
            
        </div>
    )
}

export default ChangePassAd