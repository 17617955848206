

const navlinks = [
    {name: 'HOME', link: '/'},
    //  {name: "EPISODES", link: '/episodes'},
    {name: "JOIN", link: '/join'},
    {name: "ABOUT", link: '/about'},
   
]

const question = [
    'What is the most insane question someone has ever asked you?',
    'Tell us about it and we might discuss it on the show.'
]


const subTiers = [
    {
        price: 3,
        name: 'Fan',
        perks: ['Shout Out on the Show',
                'Name on the Wall of Fandom',
                ] 

    },
    {
        price: 5,
        name: 'Big Fan',
        perks: [
                'Get the main show 2 weeks early',
                'Main show is ad-free',
                'Behind the scenes audio instead of ads',
                'Shout Out on the Show',
                'Name on the Wall of Fandom',
                ] 

    },
    {
        price: 10,
        name: 'Huge Fan',
        perks: [
                'After Hours Bonus episodes',
                'Get the main show 2 weeks early',
                'Main show is ad-free',
                'Behind the scenes audio instead of ads',
                'Shout Out on the Show',
                'Name on the Wall of Fandom',
                ] 

    },
    // {
    //     price: 15,
    //     name: 'Super Fan',
    //     perks: [
    //             'After Thoughts Bonus episodes',
    //             'After Hours Bonus episodes',
    //             'Get the main show 2 weeks early',
    //             'Main show is ad-free',
    //             'Behind the scenes audio instead of ads',
    //             'Shout Out on the Show',
    //             'Name on the Wall of Fandom',
    //             ] 

    // },
]

const dateFix = (date) => {
    let result = new Date(date)
    result.setDate(result.getDate() + 1)
    return result
}


export {
    navlinks,
    question,
    subTiers,
    dateFix,
}