import React, { useRef } from 'react';
import useVideoPlayer from '../../hooks/useVideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeMute, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';


const Audio = (props) => {
    const audio = props.audio
    const audioElement = useRef(null)
    const {
        isPlaying,
        progress,
        speed,
        isMuted,
        togglePlay,
        handelOnTimeUpdate,
        handelVideoProgress,
        handelVideoSpeed,
        toggleMute,
    } = useVideoPlayer(audioElement)


    return(
        <div className='audio' >
            <audio 
                src={audio} 
                ref={audioElement} 
                onTimeUpdate={handelOnTimeUpdate} 
                //playsInline={true} 
                //preload="none"
            />
            <div className='controlls' >
                
                <button onClick={togglePlay} className="play-btn">
                    {isPlaying ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
                </button>
                
                <input type="range" min="0" max="100" value={progress || 0} onChange={(e)=> handelVideoProgress(e)} className="slider"/>

                <select value={speed} onChange={(e) => handelVideoSpeed(e)} className="speed">
                    <option value="0.50">0.50x</option>
                    <option value="1">1x</option>
                    <option value="1.25">1.25x</option>
                    <option value="2">2x</option>
                </select>

                <button onClick={toggleMute} className="mute">
                    {isMuted ? <FontAwesomeIcon icon={faVolumeMute}/> : <FontAwesomeIcon icon={faVolumeHigh}/>}
                </button>

            </div>
            
        </div>
    )
}

export default Audio;
