import React from 'react'

import apple from './img/apple.svg'
import spotify from './img/spotify.png'
import amazon from './img/amazon.png'
import stitcher from './img/stitcher.png'
import google from './img/google.png'
 
const Badges = () => {
    return(
        <article className='badges'>
            <a title="Listen to Askin' Haskin's on Apple Podcasts" href='https://podcasts.apple.com/us/podcast/askin-haskins/id1630206687'>
                <img src={apple} alt="Listen to us on Apple Podcasts"/>
            </a>

            <a title="Listen to Askin' Haskin's on Spotify" href='https://open.spotify.com/show/6gymeNKn0sTCQULYXXmrbm'>
                <img src={spotify} alt="Listen to us on Spotify"/>
            </a>

            <a title="Listen to Askin' Haskin's on Google Podcasts" href='https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkcy5yZWRjaXJjbGUuY29tLzNiNmYyM2Y5LThhMjEtNGY3OS1iOWViLWMyOThlZjJlMTc0YQ%3D%3D'>
                <img src={google} alt="Listen to us on Google Podcasts"/>
            </a>

            <a title="Listen to Askin' Haskin's on Amizon Music" href="https://music.amazon.com/podcasts/b8badee3-4100-4ece-8e3d-8c08f2761a81/askin'-haskin's">
                <img src={amazon} alt="Listen to us on Amazon Music" width="112" height="40"/>
            </a>

            <a title="Listen to Askin' Haskin's on Stitcher" href="https://www.stitcher.com/show/askin-haskins">
                <img src={stitcher} alt="Listen to us on Stitcher" width="130"/>
            </a>

            
            <a title="Listen to Askin' Haskin's on RadioPublic" href="https://radiopublic.com/askin-haskins-GKdLPV">
                <img src="https://spotlight.radiopublic.com/images/badges/radiopublic-white.png" alt="Listen to Askin' Haskin's on RadioPublic" width="124" height="40" />
            </a>
        </article>
    )
}

export default Badges;