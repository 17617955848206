import React from 'react'
import { Link } from 'react-router-dom'
import { dateFix } from '../../../../datafile'

//css in episodes.scss
const EpisodeList = (props) => {
    
    const episodes = props.episodes 
    
    return(
        <div className='ep-list'>
            
            {episodes.map((item, index) => (
                <Link to={`/episodes/${item.id}`} className="ep-card" key={index}>
                    <p className='title'>Ep. {item.number} - {item.title}</p>
                    {Date.parse(new Date()) >= Date.parse(dateFix(item.release)) ?
                        <p className='green release'>Out Now</p>
                    :
                        <p className='date release'>{dateFix(item.release).toLocaleDateString()}</p>
                    }
                </Link>
            ))}
            
                
        </div>

       
    )
}

export default EpisodeList;