import React, { useState } from 'react'

import NavBar from '../nav/NavBar';
import Modal from '../modal/Modal';
import QuickQuestion from '../quick-question/QuickQuestion';
import logoHp from '../../../media/logo-hp.png';

const Header = () => {
    const [modalState, setModalState] = useState('none')
    return(
        <header>
            <div className='main-header'>
                <div className='logo-name'>
                    <a href="http://www.askinhaskins.com">
                        <img src={logoHp} alt="Askin' Haskin's headphones logo" style={{width:"60px"}}/>
                    </a>
                    <h1>
                        <span className='purp'>Askin'</span> Haskin's
                    </h1>
                </div>
            
                <div>
                    <p>A podcast that has all all the answers. . . </p>
                    <p>We can't promise they're correct.</p>
                </div>
                {/* <div className='btn-box'>
                    <button onClick={() => setModalState('block')}>Submit A Question</button>
                </div> */}
                <div className='email-q'>
                    <p>Email your questions to:</p>
                    <a href='mailto:questions@askinhaskins.com'>questions@askinhaskins.com</a>
                </div>
                
            </div>

            <NavBar />

            <Modal state={modalState} setState={setModalState} element={<QuickQuestion/>}/>

        </header>
    )
}

export default Header;