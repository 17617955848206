import React from 'react';
import { navlinks } from '../../../datafile';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return(
        <nav className='nav-bar'>
            <div className='inner-box'>
                {navlinks.map((link, index) => (
                    <NavLink key={index} to={link.link} className={({isActive}) => isActive ? 'activeLink link': 'inactiveLink link'}>
                        {link.name}
                    </NavLink>
                ))}
            </div>
        </nav>
    )
}

export default NavBar