import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';
import Badges from '../home/components/badges/Badges';
import EpNav from './components/EpNav';
import { dateFix } from '../../../datafile';

//img
import hours from "./img/letters-hours.png"
import thoughts from './img/letters-thoughts.png'
import logo from '../../../media/logo-hp-full.png'

import Audio from '../../common/audio/Audio';

const Ep = (props) => {

    const {id} = useParams() 
    const [episode, setEpisode] = useState({})
    const [prev, setPrev] = useState({})
    const [next, setNext] = useState({})
    const [title, setTitle] = useState('')
    const [rssData, setRssData] = useState({})
   

    useEffect(()=>{
        
        //setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/episodes/ep/${id}`, {withCredentials: true})
            .then(res => {
                setEpisode(res.data.episode)
                setPrev(res.data.prev)
                setNext(res.data.next)
                setTitle(`Ep. ${res.data.episode.number} - ${res.data.episode.title}`)
            })
            .catch(err => {
                console.log(err)
                //setIsLoading(false)
               
            })
    },[id])

    useEffect(()=>{
        
       if(episode.type === 'main'){
           axios
            .get(`${process.env.REACT_APP_API_ADD}/rss/eprss/${id}`, {withCredentials: true})
            .then(res => {
                setRssData(res.data)
                
            })
            .catch(err => {
                console.log(err)
                
            })
       }else{
           setRssData({})
       }
        
    },[id, episode.type])

    const secToTime = (seconds) => {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    }    

    //console.log(episode, rssData)

    return(
        <section className='ep'>
            {episode.type === 'main' ?
                <div className='askin'>
                    <div>
                        <h2>Askin' <span className='blu'>Haskin's</span></h2>
                        <p>Podcast</p>
                    </div>
                    
                </div>
            :
                episode.type === 'hours' ? 
                    <h2 className='hours'>After <span className='org'>Hours</span></h2>
                :
                    <h2 className='thoughts'>After <span className='lt-blu'>Thoughts</span></h2>

            }
            <h3>{title}</h3>
           
            <article className='episode-page'>
                <div className='top'>
                    <div className='img-box'>
                        {episode.type === 'main' ? 
                            rssData.image ? 
                                <img src={rssData.image} alt="Askin' Haskin's Podcast Episode Logo"/>
                            :
                                <img src={logo} alt="Askin' Haskin's Podcast Episode Logo"/>    

                        :
                            episode.type === 'hours' ? 
                                <img src={hours} alt="Askin' Haskin's After Hours Episode Logo"/>
                            :
                                <img src={thoughts} alt="Askin' Haskin's After Thoughts Episode Logo"/>
                        }

                        
                    </div>
                    
                    <div className='des-box'>
                        <div className='des'>
                            {episode.description}
                        </div>

                        <div className='info-box'>
                            {episode.release ? 
                                <p>Release: {dateFix(episode.release).toLocaleDateString()}</p>
                            :
                                null
                            }
                           
                            {episode.type === "main" && rssData.length ?
                                <p>Duration {secToTime(rssData.length)}</p>
                                
                            :
                                null
                            }
                            
                        </div>
                    </div>
                    
                </div>

                
                <div className='player-box'>
                    {rssData.url? 
                        <Audio audio={rssData.url}/>
                    :
                        episode.type === 'main' ?
                            <div className='coming-soon'>
                                Coming Soon
                            </div>
                        :

                        <a href='http://www.patreon.com/askinhaskins' className='patreon-btn'>
                            <FontAwesomeIcon icon={faPatreon} />
                            <p>Listen To This Episode On Patreon</p>
                        </a>
                    }
                    
                </div>
            
            </article>
            <h3 className='subscribe'>Subscribe To Our Podcast On These Platforms</h3>
            <Badges />

            <div className='ep-nav-window'>
                <EpNav ep={prev} />
                <EpNav ep={next} next={true}/>
            </div>
        </section>
    )
}

export default Ep;