import React, { useState }from 'react'

import QuestionWindow from './QuestionWindow';
import EpisodeWindow from './EpisodeWindow';

const DataDisplay = () => {
    const [window, setWindow] = useState("questions")

    return(
        <article className='data-display'>
            <div className='display-menu'>
                <button onClick={() => {setWindow("questions")}}>Questions</button>
                <button onClick={() => {setWindow("episodes")}}>Episodes</button>
            </div>
            {window === "questions" ? 
                <QuestionWindow />
            :
                <EpisodeWindow />
            }
            
        </article>
    )
}

export default DataDisplay;