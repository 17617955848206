import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

const About = () => {

    const [dan, setDan] = useState([])

    const randomNum = (max) => {
        let rand = Math.random() * max
        let num = Math.floor(rand)
        return num
    }

    let danIndex = randomNum(dan.length)
    let danInfo = dan[danIndex]

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_ADD}/dan/all`, { withCredentials: true })
            .then(res => {
                setDan(res.data)
            })
            .catch(err => {
                console.log("Dan error", err)
            })
    }, [])
    
    return(
        <section>
            <article className='about'>
                <h2>About Us</h2>
                <p>
                    What does a writer from Cleveland, an optometric tech from Cleveland, a corrections officer from Western NY, and a {danInfo ? danInfo.occupation : "bouncer"} from {danInfo ? danInfo.location : "New Hampshire"} have in common? They’re cousins… That’s pretty much it… Their mothers all had the same parents. So you know they are kind of forced to like each other. All joking aside these cousins are more like siblings and while they don’t technically hold the name they are all Haskin at heart. These guys have spent many nights drinking brews while contemplating the wonders of the universe and now they have decided to open up these discussions to the world.
                </p>
                <p>
                    With drinks in hand Rick, Dan, Claudette, and Matt (along with the help of their producer David) are ready to tackle your most daunting questions. It doesn’t matter how ridiculous the questions may be, they won’t shy away from any topic. We can’t promise they will have the right answer but we do promise they will have an answer.
                </p>


                <div className='profiles'>
                    <h3>Rick: Host</h3>
                    <p>The captan, the brains of the operation. Or in other words the nerdy techie dude.</p>
                    <address>
                        <a href='mailto: rick@askinhaskins.com' className='email'><FontAwesomeIcon icon={faEnvelope} /></a>
                        <a href="https://twitter.com/rj_dowd" className='twitter icon'><FontAwesomeIcon icon={faTwitter}/></a>
                        <a href="https://facebook.com/rjarrett.dowd" className='facebook icon'><FontAwesomeIcon icon={faFacebook} /></a>
                    </address>
                    
                    <h3>Matt: Co-Host</h3>
                    <p>The guy who brings the brawn, the muscle of the group… yeah we’ll stick with that… its muscle.</p>
                    <address>
                        <a href='mailto: matt@askinhaskins.com' className='email'><FontAwesomeIcon icon={faEnvelope} /></a>
                    </address>
                    
                    <h3>Dan: Co-Host</h3>
                    <p>The dude who brings the wisdom. That’s just a nice way of saying he’s the older one.</p>
                    <address>
                        <a href='mailto: dan@askinhaskins.com' className='email'><FontAwesomeIcon icon={faEnvelope} /></a>
                        <a href="https://twitter.com/DanLocey2" className='twitter icon'><FontAwesomeIcon icon={faTwitter}/></a>
                        <a href="https://facebook.com/dan.locey.718" className='facebook icon'><FontAwesomeIcon icon={faFacebook} /></a>
                    </address>
                    
                    <h3>Claudette: Co-Host</h3>
                    <p>The Girl.</p>
                    <address>
                         <a href='mailto: claudette@askinhaskins.com' className='email'><FontAwesomeIcon icon={faEnvelope} /></a>
                         <a href="https://twitter.com/TheGirlAH" className='twitter icon'><FontAwesomeIcon icon={faTwitter}/></a>
                        <a href="https://facebook.com/claudette.dowd" className='facebook icon'><FontAwesomeIcon icon={faFacebook} /></a>
                    </address>
                   
                    <h3>David: Producer</h3>
                    <p>The guy behind the scenes making it all happen. Mostly we just force him to grab us beers.</p>
                    <address>
                        <a href='mailto: david@askinhaskins.com' className='email'><FontAwesomeIcon icon={faEnvelope} /></a>
                    </address> 
                    
                </div>
            </article>
        </section>
    )
}

export default About;