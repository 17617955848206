import React from 'react'

const Modal = (props) => {
    //This is a basic modal component
    //This component takes in a display setting from parent component {props.state} = "block" or "none"
    //This compnent takes in a function from parent component that will setState to "none" when clicking x in modal-content
    //takes in a component to render inside the modal 

    const state = props.state
    const setState = props.setState
    const element = props.element

    return(
        <div className='modal' style={{display: state}}>
            <article className='modal-content' >
                <span className='close' onClick={()=> setState("none")}>{String.fromCharCode(215)}</span>
                {element}
            </article>
            
        </div>
    )
}

export default Modal