import React from 'react';
import VideoPost from './VideoPost';
import AudioPost from './AudioPost';
import TextPost from './TextPost';



//console.log(posts)

const PostCard = (props) => {
    const data = props.data

    return(
        <article className='post-card'>
            <div className='container'>
                {data.type === 'video' ?
                    <VideoPost data={data} />
                :
                    data.type === 'audio'? 
                        <AudioPost data={data} />
                    :
                        <TextPost data={data}/>
                }
                {/* <VideoPost data={posts[0]} /> */}
                {/* <AudioPost data={posts[1]} /> */}
                {/* <TextPost data={posts[2]}/> */}
            </div>
            
        </article>
    )
}

export default PostCard;