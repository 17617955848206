import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';

import TierDisplay from './components/TierDisplay';

const Join = () => {
    return(
        <section className='join'>
            <header><h2>Join</h2></header>
            <p>
                Join us on our journey and help us make this show into a professional production… Well at least as professional as this group can handle. If you are a fan of the show and would like to contribute to our growth please consider subscribing to our Patreon page. And for all of your support we will throw you a little something in return.  
            </p>
            <div className='patreon'>
                <a href='https://www.patreon.com/askinhaskins'>
                    <FontAwesomeIcon icon={faPatreon} className='pat-logo'/>
                    <p>patreon.com/askinhaskins</p>
                </a>
                
                
            </div>
            
            <TierDisplay />

            <div className='message'>
                <h5>A message to all our fans</h5>
                <p>It’s going to take some time but we have many plans in the works. First and foremost we will be finding ways to add even more to these tiers like Discord access or even special perks added to this website as we are in the beginning stages of planning the community side of the site. The point being is that we want to be in the position to give you more and more for being the driving force behind what we do here. Subscribe to our Patreon and help us turn this monthly podcast into a daly livestream.</p>
                <p>Last, but definitely not least, we want to acknowledge that we appreciate all forms of support. From simple likes and shares on our social sites, to the biggest form of support (in our opinion)  taking the time to listen to our show. We understand that not everyone will be able to support us monetarily and we want you to know that this does not diminish our appreciation. It may not be anytime soon but we want you to know that we will one day try to find a way to reward you for all your support as well. Unless this thing tumbles to the ground and goes up in flames and with these guys who know what can happen. With all jokes aside all of us at Askin’ Haskin’s love every single one of our fans just for listening to us. Thank you so much!</p>
            
            </div>
            
        </section>
    ) 
}

export default Join