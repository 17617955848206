import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const EpNav = (props) => {
    const ep = props.ep
    const next = props.next

    //console.log(ep)
    return(
        <article className='ep-nav'>

            {ep === null || ep === undefined ?
                <Link to={'/episodes'}>
                    <header className={next ? "header-next" : ""}>
                        <div >
                            <FontAwesomeIcon icon={next ? faChevronRight : faChevronLeft} />
                            <FontAwesomeIcon icon={next ? faChevronRight : faChevronLeft} />
                        </div>
                        <p className={next ? "next" : "prev"}>
                            {next ? "Next" : "Previous"}
                        </p>
                    </header>
                    <p>Back to Episodes</p>
                </Link>
            :
                
                <Link to={`/episodes/${ep.id}`}>
                    <header className={next ? "header-next" : ""}>
                        <div>
                            <FontAwesomeIcon icon={next ? faChevronRight : faChevronLeft} />
                            <FontAwesomeIcon icon={next ? faChevronRight : faChevronLeft} />
                        </div>
                        <p className={next ? "next" : "prev"}>
                            {next ? "Next" : "Previous"}
                        </p>
                    </header>
                    <div>
                        {ep.type === 'main' ? 
                            <p>Askin' Haskin's</p>
                        :
                            ep.type === 'hours' ?
                                <p>After Hours</p>
                            :
                                <p>After Thoughts</p>
                        }

                        <p>{`Ep. ${ep.number} - ${ep.title}`}</p>
                    </div>
                </Link>
            }
            
        </article>
    )
}

export default EpNav;