import React, { useState } from 'react';
//import { Link } from 'react-router-dom';

import QuickDescrip from './components/quickdescrip/QuickDescrip';
//import Schedule from './components/schedule/Schedule';
//import Audio from '../../common/audio/Audio';
import Modal from '../../common/modal/Modal';
import QuickQuestion from '../../common/quick-question/QuickQuestion';
import WhatsNew from './components/whatsnew/WhatsNew';
import Badges from './components/badges/Badges';

const Home = () => {

    const [modalState, setModalState] = useState('none')
    

    //console.log(danInfo)
    return (
        <section className='home'>
            <Modal state={modalState} setState={setModalState} element={<QuickQuestion/>}/>
            <article className='coming'>
                <h2>New Episodes Drop Every Month</h2>
                <h3>Subscribe to gain access to episodes 2 weeks early along with other perks</h3>
            </article>

            <div className='top-window'>
                <div className='left-side'>
                    <QuickDescrip  setModalState={setModalState}/>
                    
                </div>


                <div className='right-side'>
                    {/* <Schedule/> */}

                    <p className='temp-text'> 
                        We've got some beef with our backend provider and because of that our capabilities have been limited. 
                        As we search for a sutable replacement there will be no release schedule or episode archive or any of the many many things we had planed for this website.
                        We are a podcast with a limited budget and becasue of that we need to prioritze where those dollars go, unfortunately our website is not a top prority at the moment. 
                        Shhhh! Don't Rick we said that. He's not gunna be happy. Anyway there are up's and down to all endevors and this won't stop us from working our way back to better than where we were.
                        There are still many amaizing things that we will one day provide for you on this website. Just give us some time and you will see this site will be a very important part of what we do.   
                    </p>
                </div>
            </div>
            <div>
                <Badges />
            </div>
            <div>
                <WhatsNew />
            </div>

            {/* <Audio audio={'https://stream.redcircle.com/episodes/14ff3762-d9d4-4ba9-a176-83fe1ab87a42/stream.mp3'}/> */}

        </section>
    )
}

export default Home;

