import React from 'react'
//import Logo from '../logo/Logo'
import logoHp from "../../../media/logo-hp.png";

const Footer = () =>{

    return(
        <footer className='main-footer'>
            <div className='logo-container'>
                <a href='http://www.askinhaskins.com'>
                    <img src={logoHp} alt="Askin' Haskin's headphones logo" style={{width:"60px"}}/>
                </a>

                
            </div>

            <p className='name'>Askin' <span className="blu"> Haskin's</span></p>
            <p className='copyright'> Copyright {String.fromCharCode(169)} {new Date().getFullYear()}</p>
            
            <address>
                <a href='mailto:info@askinhaskins.com'>
                    info@askinhaskins.com
                </a>
                 
            </address>

            {/* <div className='sandbox'>
                <Logo size={700} first={'0'} second={'1'} />
            </div> */}
            
            
        </footer>
    )
}

export default Footer