import React from 'react';

//css in postcard.scss
const TextPost = (props) => {

    const data = props.data
    return(
        <div className='audio-post'>
            <div>
                <header>
                    <h2>{data.title}</h2>
                    <p>Posted on: {data.date}</p>
                </header>
            </div>
            <div className='description'>
                {data.description}
            </div>
            <div className='links'>
                {data.links.length > 0 ?
                    data.links.map((link, index) => (
                        <div key={index} >
                            <p>{link.pretext}</p>
                            <a href={link.address}>{link.linktext}</a>
                            <p>{link.posttext}</p>
                        </div>
                    ))
                :
                    null
                }
            </div>

            {data.media != null ? 
                <img src={data.media} alt={data.alt} />
            :
                null    
            }
        </div>
    )
}

export default TextPost;