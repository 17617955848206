import React, {useState} from 'react'
//import axios from "axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

import Modal from '../../../common/modal/Modal';
import EditEpisode from './EditEpisode';

const EpisodeCard = (props) => {

    const {type, number, title, description, release, tier, media, link} = props.data
    //const setMessage = props.setMessage
    const [oppWindow, setOppWindow] = useState({height: "0px"})
    const [windowState, setWindowState] = useState(0)
    const [modalState, setModalState] = useState("none")
    

    const windowOpperation = () => {
        if(!windowState){
            setOppWindow({height: "220px", overflowY: "scroll"})   
            setWindowState(1)
        }else{
            setOppWindow({height: "0px"})
            setWindowState(0)
        }
    }


    // const deleteEpisode = () =>{
    //     axios
    //         .delete(`${process.env.REACT_APP_API_ADD}/episodes/delete/${id}`, {withCredentials: true})
    //         .then(res =>{
    //             setMessage(res.data.message)
    //         })
    //         .catch(err=>{
    //             console.log(err)
    //         })
    // }

    const typeColor = (type) => {
        let color = ""
        if(type === "main"){
            color="white"
        }else if(type === "thoughts"){
            color="purple" 
        }else{
            color="blue"
        }

        return color
    }

    return(
        <div className='episode-card'>
            <div className={`${typeColor(type)} row`}>
                <div className='col-1'>
                    <p className="">{number}</p>
                </div>
                <div className='col-2'>
                    <p className="">{title}</p>
                </div>
                <div className='col-3'>
                    <p className="">Release: {release}</p>
                </div>
                <div className='col-4'>
                    <div className='' onClick={()=> windowOpperation()}>
                        {!windowState? 
                            <FontAwesomeIcon icon={faAngleDown}/> 
                        : 
                            <FontAwesomeIcon icon={faAngleUp}/>
                        }
                    </div>
                    <div onClick={()=> setModalState("block")}>
                        <FontAwesomeIcon icon={faPenToSquare}/>
                    </div>
                </div>
            </div>

            <div className='full-window' style={oppWindow}>
                <h2>Description: </h2>
                <p>{description}</p>
                
                <div>
                    <h2>Type:</h2>
                    <p>{type}</p>
                </div>
                <div>
                    <h2>Tier:</h2>
                    <p>{tier}</p>
                </div>
                <div>
                    <h2>Media:</h2>
                    <p>{media}</p>
                </div>
                <div>
                    <h2>Link:</h2>
                    <p>{link}</p>
                </div>
                
            </div>
            <Modal state={modalState} setState={setModalState} element={<EditEpisode data={props.data}/>}/>
        </div>
    )
}

export default EpisodeCard;