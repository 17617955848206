import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import QuestionCard from './QuestionCard'

const QuestionWindow = () => {

    const [questions, setQuestions] = useState([])
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/questions/all`, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setQuestions(res.data)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    },[message])

    //console.log(questions)
    return(
        <article className='questions-window'>
            
            {isLoading ? 
                <div className='loading'> 
                    <FontAwesomeIcon icon={faSpinner} spin/> 
                </div>
            :
                <div>
                    <div className='row btm-dashed'>
                        <div className='col-1'>
                            Name:
                        </div>
                        <div>
                            Subject:
                        </div>
                    </div>
                    {questions.length > 0 ? 
                        
                        questions.map((data, index) =>(
                            <div key={index}>
                                <QuestionCard data={data} setMessage={setMessage}/>
                            </div>
                        ))
                    :
                        null
                    }
                </div>
            }
            
            
            
            <p className='message'>{message}</p>

        </article>
    )
}

export default QuestionWindow