import React from  'react'
import {Routes, Route} from 'react-router-dom'
//styles
import './styles/stylesheet.scss'
//components
import Header from './components/common/header/Header';
import Home from './components/page/home/Home';
import Admin from './components/page/admin/admin';
import About from './components/page/about/About';
import Join from './components/page/join/Join';
import Footer from './components/common/footer/Footer';
import Episodes from './components/page/episodes/Episodes';
import Ep from './components/page/ep/Ep';

function App() {
  return (
    <main >
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/join" element={<Join/>}/>
        <Route path="/episodes" element={<Episodes/>} />
        <Route path="/episodes/:id" element={<Ep/>} />
        <Route path='/xadminx' element={<Admin/>} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
