import React, { useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import UserWindow from './UserWindow'
import Wwd from './Wwd'
import AddEpisode from './AddEpisode';
import DataDisplay from './DataDisplay';


const AdminPanel = (props) =>{

    const adminData = props.adminData
    const setAdminData = props.setAdminData
    const setMessage = props.setMessage
    const message = props.message

    const [isLoading, setIsLoading] = useState(false)

    const logout = () =>{
        setIsLoading(true)
        axios
            .post(`${process.env.REACT_APP_API_ADD}/admin/logout`, {id: adminData.id}, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setAdminData(null)
                setMessage(res.data.message)
                //console.log(res.data)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    //console.log(adminData)

    return(
        <div className=''>
            {isLoading ?
                <div className='loading3'> 
                    <FontAwesomeIcon icon={faSpinner} spin/> 
                </div>
            :
                <div className='admin-panel'>
                    <DataDisplay />
                    <div className='user-wndw'>
                        <UserWindow logout={logout} message={message} adminData={adminData}/>
                        <Wwd role={adminData.role} />
                        <AddEpisode />
                    </div>
                </div>
            }
            
            
            
        </div>
    )
}

export default AdminPanel