import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const EditEpisode = (props) => {
    const {id, type, number, title, description, release, tier, media, link} = props.data
    const [isLoading, setIsLoading ] = useState(false)
    const [message, setMessage] = useState("")

    const editEpisode = (values) =>{
        setIsLoading(true)
        axios
            .put(`${process.env.REACT_APP_API_ADD}/episodes/edit/${id}`, values, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setMessage("Entry submitted")
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                setMessage("A problem occured")
            })
    }

    const formSchema = Yup.object().shape({
        type: Yup.string().required("Required"),
        number: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        release: Yup.date(),
        media: Yup.string(),
        link: Yup.string(),
        tier: Yup.number()
       
    })

    return(

        //css same as addepisode
        <article className='add-episode modal-adjust'>
            <div>
                {isLoading ? 
                    <div className='loading4'> 
                        <FontAwesomeIcon icon={faSpinner} spin/> 
                    </div>
                :
                    <div>
                        <h3>Edit Episode {number} - {title}</h3>
                        <p>{message}</p>
                        <Formik
                            initialValues={{
                                type: type,
                                number: number,
                                title: title,
                                description: description,
                                release: release,
                                media: media,
                                link: link,
                                tier: tier
                                
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, {resetForm}) =>{
                                //console.log(values)
                                editEpisode(values)
                                resetForm()
                            }}
                        >
                            {({errors, touched}) =>(
                                <Form>
                                    <div className="flex-box">
                                        <div>
                                            <div>
                                                <label htmlFor='type'>Type:</label>
                                            </div>
                                            <div>
                                                <Field as="select" name="type" className="select">
                                                    <option value="main">Main Show</option>
                                                    <option value="hours">After Hours</option>
                                                    <option value="thoughts">After Thoughts</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="spacer">
                                            <div>
                                                <label htmlFor='number'>Episode Number:</label>
                                                {errors.number && touched.number? (
                                                    <span className='red'> {errors.number}</span>
                                                ): null}
                                            </div>
                                            <div>
                                                <Field name="number" type="text" className="field"/>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mar">
                                        <div>
                                            <label htmlFor='title'>Episode Title:</label>
                                            {errors.title && touched.title? (
                                                <span className='red'> {errors.title}</span>
                                            ): null}
                                        </div>
                                        <div>
                                            <Field name="title" type="text" className="field"/>
                                        </div>
                                    </div>

                                    <div className="mar">
                                        <div>
                                            <label htmlFor='description'>Episode Description:</label>
                                            {errors.description && touched.description? (
                                                <span className='red'> {errors.description}</span>
                                            ): null}
                                        </div>
                                        <div>
                                            <Field name="description" as="textarea" className="field textarea"/>
                                        </div>
                                    </div>

                                    <div className="flex-box">
                                        <div>
                                            <div>
                                                <label htmlFor='release'>Release Date:</label>
                                                {errors.release && touched.release? (
                                                    <span className='red'> {errors.release}</span>
                                                ): null}
                                            </div>
                                            <div>
                                                <Field name="release" type="date" className="select date"/>
                                            </div>
                                        </div>
                                        <div className="spacer">
                                            <div>
                                                <label htmlFor='tier'>Tier:</label>
                                            </div>
                                            <div>
                                                <Field as="select" name="tier" className="select">
                                                    <option value={1}>None</option>
                                                    <option value={2}>Fan</option>
                                                    <option value={3}>Big Fan</option>
                                                    <option value={4}>Huge Fan</option>
                                                    <option value={5}>Super Fan</option>
                                                </Field>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mar">
                                        <div>
                                            <label htmlFor='media'>Media:</label>
                                            {errors.media && touched.media ? (
                                                <span className='red'> {errors.media}</span>
                                            ): null}
                                        </div>
                                        <div>
                                            <Field name="media" type="text" className="field"/>
                                        </div>

                                    </div>

                                    <div className="mar">
                                        <div>
                                            <label htmlFor='link'>Link:</label>
                                            {errors.link && touched.link ? (
                                                <span className='red'> {errors.link}</span>
                                            ): null}
                                        </div>
                                        <div>
                                            <Field name="link" type="text" className="field"/>
                                        </div>

                                    </div>

                                    <button type="submit" className="btn">Edit Episode</button>


                                </Form>
                            )}
                            
                        </Formik>
                    </div>
                
                }
            </div>
        </article>
    )
}

export default EditEpisode