import React from 'react';

import PostCard from '../../../../common/postcard/PostCard';
import { posts } from '../../../../../postfile';



const WhatsNew = () => {
    return(
        <article className='whats-new'>
            <div className='header'>
                <h2>What's <span className='txt-ppl'>New</span></h2>
            </div>
            
            
            <div className='post-list'>
                {posts.map((data, index) => (
                    <PostCard key={index} data={data} />
                ))}    
            </div>
        </article>
    )
}

export default WhatsNew