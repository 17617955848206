import React from 'react'

const Logo = (props) =>{

    const size = props.size || 60;
    const insideSize = size - 4
    const fontSize = Math.floor(size/12)
    const first = props.first || 'A'
    const second = props.second || 'H'

    return(
        <article className='logo' style={{width: `${size}px`, height: `${size}px`}}>
            <div className='inner-box' style={{minWidth: `${insideSize}px`, minHeight: `${insideSize}px`}}>
                <div className='text' style={{fontSize: `${fontSize}rem`}}>
                    <span className='a'>{first}</span>
                    <span className='h'>{second}</span>
                </div>
            </div>
        </article>
    )


}

export default Logo;