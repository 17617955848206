import React from 'react'
import Video from '../video/Video'

//css in postcard.scss
const VideoPost = (props) => {

    const data = props.data

    //console.log(data)
    return(
        <div className='video-post'>
            <div>
                <Video video={data.media} />
            </div>
            <div className='info-box'>
                <header>
                    <h2>{data.title}</h2>
                    <p>Posted on: {data.date}</p>
                </header>

                <div className='description'>
                    {data.description}
                </div>
                <div className='links'>
                    {data.links.length > 0 ?
                        data.links.map((link, index) => (
                            <div key={index}>
                                <p>{link.pretext}</p>
                                <a href={link.address}>{link.linktext}</a>
                                <p>{link.posttext}</p>
                            </div>
                        ))
                    :
                        null
                    }
                </div>
               
            </div>
        </div>
    )
}

export default VideoPost;