import React from 'react'
import { subTiers } from '../../../../datafile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPatreon } from '@fortawesome/free-brands-svg-icons'


const TierDisplay = () => {

    //console.log(subTiers)
    return(
        <article className='tier-display'>
            <h3>Our Subscription Tiers</h3>
            <div className='container'>
                {subTiers.map((tier, index) => (
                    <div className='tier-card' key={index}>
                        <div>
                            <p className='price'>${tier.price}<span className='month'>/mo</span></p>
                            
                            <h4>{tier.name}</h4>
                            <div className='perks'>
                                {tier.perks.map((perk, index) => (
                                    <p key={index}>{perk}</p>
                                ))}
                            </div>
                            
                        </div>
                        <div className='btn-container'>
                            <button>
                                <a href='https://www.patreon.com/askinhaskins'>
                                    <FontAwesomeIcon icon={faPatreon}/>
                                    <span>Become a Patron</span>
                                </a>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className='b-e-descrip'>
                <h5>After Hours:</h5>
                <p>After Hours is a bonus episode where we throw the standard format out the window. These are recorded after the main show so you know we've had a bit to drink. We may answer questions or just talk about anything, who know and who cares it's the After Hours there are no rules. Episodes are posted every 2-3 months.</p>

                <h5>After Thoughts:</h5>
                <p>After Thoughts is a weekly recap episode where we dive into the most recent episode and dig into questions that may not have made it on show. These half-hour to an hour episodes will occationaly drift off into other more relevant topics as well. After Thoughts episodes begin posting on launch day June 18th 2022. </p>

            </div>
            

        </article>
    )
}

export default TierDisplay