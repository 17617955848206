import React from 'react';

// dimport Questions from '../forms/questions/Questions';
import { question } from '../../../datafile';

const QuickQuestion = () => {
    return(
        <article className='quick-question'>
            <header className='colored-text'>
                <p>We will answer anything</p>
                <p>From the complex contemplations of the nature of man</p>
                <p>To the dumbest queries that diminish the cells in our brains</p>
                <p>It don’t mater what type of question it is, we want them</p>
                <p>Fill out the form below to get your question on the show</p>

            </header>
            <section className='form-box'>
                {/* <Questions /> */}
                <div className='socal-q'>
                    <h3>Our form is down at the moment</h3>
                    <p>Please submit your questions to our Facebook or Twitter pages.</p>
                    <a href='https://www.facebook.com/askinhaskins'>Askin' Haskin's Facebook Page</a>
                    <a href='https://www.twitter.com/askinhaskins'>Askin' Haskin's Twitter Page</a>
                    <p>Patreon members can submit quetions on patreon and get prority over the other ways to submit.</p>
                    <a href='https://www.patreon.com/askinhaskins'>Askin' Haskin's Patreon Page</a>
                    <p>Lastly you can email us any questions you have. If youd like to remain anonomus email or patreon is the only way to do so. Just write 'anon' at the top of your email or message. </p>
                    <a href='mailto:questions@askinhaskins.com'>
                    questions@askinhaskins.com
                </a>
                </div>
            </section>
            <footer className='request'>
                <p>Don’t have a question?</p>
                {question.map((text, index) => (
                    <p key={index}>
                        {text}
                    </p>
                ))}
                
            </footer>
        </article>
    )
}

export default QuickQuestion;