import { useState, useEffect } from "react";

const useVideoPlayer = (videoElement) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [speed, setSpeed] = useState(1)
    const [isMuted, setIsMuted] = useState(false)

    const togglePlay = () => {
        setIsPlaying(!isPlaying)
    }

    useEffect(() =>{
        isPlaying ? videoElement.current.play() : videoElement.current.pause()
    },[isPlaying, videoElement])

    const handelOnTimeUpdate = () => {
        const currentProgress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
        setProgress(currentProgress)
    }

    const handelVideoProgress = (e) => {
        const manualChange = Number(e.target.value)
        videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange
        setProgress(manualChange)
    }

    const handelVideoSpeed = (e) => {
        const currentSpeed = Number(e.target.value);
        videoElement.current.playbackRate = currentSpeed;
        setSpeed(currentSpeed)
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
    }

    useEffect(() => {
        isMuted ? (videoElement.current.muted = true) : (videoElement.current.muted = false)
    },[isMuted, videoElement])

    return {
        isPlaying,
        progress,
        speed,
        isMuted,
        togglePlay,
        handelOnTimeUpdate,
        handelVideoProgress,
        handelVideoSpeed,
        toggleMute,
    }
}

export default useVideoPlayer