import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import {Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const Wwd = (props) => {

    const [isLoading, setIsLoading ] = useState(false)
    const [wwd, setWwd] = useState([])
    const [message, setMessage] = useState("")

    const role = props.role

    const formSchema = Yup.object().shape({
        occupation: Yup.string().required("Required"),
        location: Yup.string().required("Required")
       
    })

    useEffect(()=>{
        setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/dan/all`, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setWwd(res.data)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                setMessage("A problem occured")
            })
    },[message])

    const submitWwd = (values) =>{
        setIsLoading(true)
        axios
            .post(`${process.env.REACT_APP_API_ADD}/dan/`, values, {withCredentials: true})
            .then(res => {
                setIsLoading(false)
                setMessage("Entry submitted")
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                setMessage("A problem occured")
            })
    }

    //console.log(wwd)

    return(
        <article className="wwd-window">
            
            <div>
                {isLoading ? 
                    <div className='loading4'> 
                        <FontAwesomeIcon icon={faSpinner} spin/> 
                    </div>
                :
                    <div className="wwd-inner-window">
                        <h4>What and where is Dan?</h4>
                        {wwd.map((item, index) => (
                            <div key={index} className="wwd-card">
                                <p>Episode {index + 1}</p>
                                <p>{item.occupation}</p>
                                <p>{item.location}</p>
                            </div>
                        ))}
                    </div>

                }
                {role > 0 ? 
                    <div>
                        <Formik
                            initialValues={{
                                occupation: '',
                                location: '',
                                
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, {resetForm}) =>{
                                console.log(values)
                                submitWwd(values)
                                resetForm()
                            }}
                        >
                            {({errors, touched}) =>(
                            <Form className="form-window">
                                <p>{message}</p>
                                <div>
                                    <label htmlFor='occupation'>Occupation:</label>
                                    {errors.occupation && touched.occupation ? (
                                        <span className='red'> {errors.occupation}</span>
                                    ): null}
                                </div>
                                <div>
                                    <Field name="occupation" type="text" className="field"/>
                                </div>

                                <div>
                                    <label htmlFor='location'>Location:</label>
                                    {errors.location && touched.location ? (
                                        <span className='red'> {errors.location}</span>
                                    ): null}
                                </div>
                                <div>
                                    <Field name="location" type="text" className="field"/>
                                </div>

                                <button type="submit" className="btn">Add to List</button>
                            </Form>
                            )}
                        </Formik>
                    </div>
                :
                    null
                }
            </div>
               

            
        </article>
    )
}

export default Wwd;