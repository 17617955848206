import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import AdminLogin from './components/AdminLogin'
import AdminPanel from './components/AdminPanel'

const Admin = () => {
    const [message, setMessage] = useState()
    const [adminData, setAdminData] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() =>{
        setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/admin/check`,{withCredentials: true})
            .then(res => {
                //setMessage(res.data)
                setAdminData(res.data.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.log("check error")
            })
    },[])

    //console.log(message)
    return(
        <section className='admin'>
            {isLoading ? 
                <div className='loading'> 
                    <FontAwesomeIcon icon={faSpinner} spin/> 
                </div>
            :
           
                <div>
                   {adminData === null? 
                        <AdminLogin setMessage={setMessage} setAdminData={setAdminData} message={message}/>
                    :
                        <AdminPanel adminData={adminData} setMessage={setMessage} setAdminData={setAdminData} message={message}/>
                    } 
                </div>
  
            }
            
        </section>
    )
}

export default Admin;