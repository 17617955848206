import React, { useRef } from 'react';
import useVideoPlayer from '../../hooks/useVideoPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeMute, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

const Video = (props) => {

    const video = props.video
    const videoElement = useRef(null)
    const {
        isPlaying,
        progress,
        speed,
        isMuted,
        togglePlay,
        handelOnTimeUpdate,
        handelVideoProgress,
        handelVideoSpeed,
        toggleMute,
    } = useVideoPlayer(videoElement)


    return(
        <div className='video'>
            <div className='container'>
                <video
                    src={video}
                    ref={videoElement}
                    onTimeUpdate={handelOnTimeUpdate}
                    //playsInline={true}
                   
                />
                <div className='controlls'>
                    <div>
                        <button onClick={togglePlay} className="play-btn">
                            {isPlaying ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
                        </button>
                    </div>

                    <input type="range" min="0" max="100" value={progress} onChange={(e)=> handelVideoProgress(e)} className="slider"/>

                    <select value={speed} onChange={(e) => handelVideoSpeed(e)} className="speed">
                        <option value="0.50">0.50x</option>
                        <option value="1">1x</option>
                        <option value="1.25">1.25x</option>
                        <option value="2">2x</option>
                    </select>
                    <button onClick={toggleMute} className="mute">
                        {isMuted ? <FontAwesomeIcon icon={faVolumeMute}/> : <FontAwesomeIcon icon={faVolumeHigh}/>}
                    </button>


                </div>
            </div>
        </div>
    )
}

export default Video