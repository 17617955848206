import React, {useEffect, useState} from 'react';
import axios from 'axios';

import RssPlayer from '../../common/rssplayer/RssPlayer';
import EpisodeList from './components/EpisodeList';

const Episodes = () => {

    const [episodes, setEpisodes] = useState({})

    useEffect(()=>{
        //setIsLoading(true)
        axios
            .get(`${process.env.REACT_APP_API_ADD}/episodes/type`, {withCredentials: true})
            .then(res => {
                setEpisodes(res.data)

            })
            .catch(err => {
                console.log(err)
                //setIsLoading(false)
            })
    },[])

    //console.log(episodes)
   
    return (
        <section className='episodes'>
            <header>
                <h2>Episodes</h2>
                <p className='headline'>Listen to the latest episode right here or browse trough our Episode Archive.</p>
            </header>
            <div className='player-window'>
                <RssPlayer />
            </div>
            <h4 className='ep-title'>Episode Archive</h4>
            <p className='direction'>click an episode for more info</p>
            <div className='catalogue'>
                <article className='ends'>
                    <header>
                        <h5>Askin' <span className='blu'>Haskin's</span></h5>
                        <h6>Podcast</h6>
                    </header>
                    <EpisodeList episodes={episodes.main || []} />
        
                </article>
                <article className='middle'>
                    <header>
                        <h5 className='hours'>After<span className='orange'> Hours</span></h5>
                    </header>
                    <EpisodeList episodes={episodes.hours || []} />
                </article>
                <article className='ends'>
                    <header>
                        <h5 className='thoughts'>After<span className='lt-blu'> Thoughts</span></h5>
                    </header>
                    <EpisodeList episodes={episodes.thoughts || []} />
                </article>

            </div>
            
        </section>
    )
}

export default Episodes;