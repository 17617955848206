import React, {useState} from 'react'
import axios from "axios"

const QuestionCard = (props) => {

    const {id, name, subject, used, body} = props.data
    const setMessage = props.setMessage
    const [oppWindow, setOppWindow] = useState({height: "0px"})
    const [windowState, setWindowState] = useState(0)
    

    const windowOpperation = () => {
        if(!windowState){
            setOppWindow({height: "200px", overflowY: "scroll"})   
            setWindowState(1)
        }else{
            setOppWindow({height: "0px"})
            setWindowState(0)
        }
    }

    const markOpperation = () =>{
        let mark
        if(!used){
            mark = 1
        }else{
            mark = 0
        }
        axios
            .put(`${process.env.REACT_APP_API_ADD}/questions/used/${id}`, {used: mark}, {withCredentials: true})
            .then(res =>{
                setMessage(`Message ${res.data.id}: ${res.data.message}`)
            })
            .catch(err =>{
                console.log(err)
            })

    }

    const deleteQuestion = () =>{
        axios
            .delete(`${process.env.REACT_APP_API_ADD}/questions/remove/${id}`, {withCredentials: true})
            .then(res =>{
                setMessage(res.data.message)
            })
            .catch(err=>{
                console.log(err)
            })
    }

    return(
        <div className='btm-dashed'>
            <div className='row'>
                <div className='col-1'>
                    <p className={used  ? "purp" : null}>{name}</p>
                </div>
                <div className='col-2'>
                    <p className={used  ? "purp" : null}>{subject}</p>
                </div>
                <div className=' col-3 q-options'>
                    <button className='read-btn' onClick={()=> windowOpperation()}>
                        {!windowState? "Open" : "Close"}
                    </button>

                    <button className={!used ? "mark-btn-1" : "mark-btn-0"} onClick={()=> markOpperation()}>
                        Mark
                    </button>

                    <button className='delete-btn' onClick={()=> deleteQuestion()}>
                        Delete
                    </button>
                </div>
            </div>

            <div className='full-q-window' style={oppWindow}>
                <h2>Subject: {subject}</h2>
                <p>{body}</p>
                
            </div>
        </div>
    )
}

export default QuestionCard