import React, {useState} from 'react'
import Modal from '../../../common/modal/Modal'

import ChangePassAd from '../../../common/forms/changepassad/ChangePassAd'
import ChangeCodeAd from '../../../common/forms/changepassad/ChangeCodeAd'

const UserWindow = (props) =>{

    const logout = props.logout
    const message = props.message
    const adminData = props.adminData

    const [passModalState, setPassModalState] = useState("none")
    const [codeModalState, setCodeModalState] = useState("none")

    return(
        <div>
            <article className='user-window'>
                <p className='message'>{message}</p>
                <p className='hello'>Hello</p>
                <p className='name'>{adminData.adminname}</p>
                <button className="logout-btn"onClick={() => {logout()}}>logout</button>
                <button className='reset-pass' onClick={()=> setPassModalState("block")}>change password</button>
                <button className='reset-code' onClick={()=> setCodeModalState("block")}>change code</button>
                
            </article>
            <Modal state={passModalState} setState={setPassModalState} element={<ChangePassAd adminData={adminData}/>}/>
            <Modal state={codeModalState} setState={setCodeModalState} element={<ChangeCodeAd adminData={adminData}/>}/>
        </div>
        
    )
}

export default UserWindow